import { Observable } from "rxjs";
import { Joint } from "../models/joint";
import { JointChange } from "../models/joint-change";

export abstract class IJointRepository {
    abstract getAll(): Observable<Joint[]>;
    abstract getJointsByPlantId(plantId: string): Observable<Joint[]>;
    abstract getJointsByProjectId(projectId: string): Observable<Joint[]>;
    abstract get(jointId: string): Observable<Joint>;
    abstract getChanges(jointId: string): Observable<JointChange[]>;
    abstract getJointImages(fileInfo: any): Observable<any>;
}

